//import React from 'react';
//import { Authenticator } from '@aws-amplify/ui-react';
//import { Amplify } from 'aws-amplify';
//import outputs from './amplify_outputs.json';
//import '@aws-amplify/ui-react/styles.css';
//import './App.css'; // Import your custom styles here
//
//Amplify.configure(outputs);
//
//function App() {
//  return (
//    <div className="app">
//      <header>
//        <h1>LeanLyf</h1>
//        {/* Navigation links can go here */}
//      </header>
//      <main>
//        <Authenticator>
//          {({ signOut, user }) => (
//            <div className="auth-wrapper">
//              <h2>Hello {user?.username}</h2>
//              <button onClick={signOut}>Sign out</button>
//            </div>
//          )}
//        </Authenticator>
//        {/* Your content goes here */}
//      </main>
//      <footer>
//        {/* Footer content can go here */}
//      </footer>
//    </div>
//  );
//}
//
//export default App;


// App.js
import React from 'react';
import './AppTemp.css';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Products from './components/Products';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from './LeanLyf_landingPage_animation.json';
import standupMate from './StandUpMate_Animation.json';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


import LeanLyfLogo from './images/LeanLyf_Logo.png';
function App() {
  return (
    <Router>
    <div className="App">
      <NavBar />
      <div className="animation-container">
        <Player
          autoplay
          loop
          src={animationData}
          style={{ height: '300px', width: '300px' }}
        />
      </div>
      <Hero />
      <Products />
    <footer className="footer">
        <p>&copy; 2024 LeanLyf</p>
        <nav className="footer-nav">
          <a href="#">Privacy</a>
          <a href="#">Terms</a>
          <a href="#">Settings</a>
        </nav>
      </footer>
    </div>
    </Router>
  );
}

export default App;
