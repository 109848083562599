// components/Products.js
import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import standupMate from '../StandUpMate_Animation.json';

function Products() {
  return (
    <section className="products" id="products">
      <div className="container">
        <h2 className="section-title">Our Products</h2>
        <div className="product">
          <div className="left-side">
            <h2>Standup Mate</h2>
            <div>
              <Player
                autoplay
                loop
                src={standupMate}
                style={{ height: '300px', width: '300px' }}
              />
              </div>
          </div>
          <div className="right-side">
            <p><b>Effortlessly manage tasks, collaborate with your team, and achieve more together. </b></p>
            <a href="https://www.standupmate.leanlyf.com/" className="btn btn-secondary"><b> Try now ! </b></a>
          </div>
        </div>
        <div>
            <h2> More Products coming soon !!! </h2>
            <h3> If you have any specific feature request, please send us an email to leanlyf.developer@gmail.com </h3>
        </div>
      </div>
    </section>
  );
}

export default Products;
