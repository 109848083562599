// components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import '../AppTemp.css';
import LeanLyfLogo from '../images/LeanLyf_Logo.png';
import outputs from '../amplify_outputs.json';
import { signInWithRedirect, fetchUserAttributes, signOut } from "aws-amplify/auth"
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
Amplify.configure(outputs);

const cookieStorage = new CookieStorage({
    domain: ".leanlyf.com",
    path: "/",
    sameSite: "none",
    secure: true,
    expires: 365,
});


cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);


function Navbar() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const userData = await fetchUserAttributes();
      setUser(userData);
      console.log(userData);
    } catch (error) {
      setUser(null);
    }
  };

  async function handleSignOut () {
    try {
      await signOut();
      setUser(null);
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };

    const renderAuthButton = () => {
      if (user) {
        return (
          <div className="user-icon">
            {/* Display user's icon or username */}
            {user.given_name}

            <button className="get-started"  type="button" onClick={handleSignOut}>
              Sign out
            </button>
          </div>
        );
      } else {
        return (
          <button onClick={() => signInWithRedirect({ provider: "Google" })} className="get-started">
            Sign In
          </button>
        );
      }
    };

  return (
   <header className="header">
      <div className="logo">
        <img src={LeanLyfLogo} alt="LeanLyf Logo" />
      </div>
      <nav className="nav-links">
        {/* Add navigation links here */}
      </nav>
      {renderAuthButton()}
    </header>
  );
}

export default Navbar;
